import Vue from "vue"

import Vuex from "vuex"

import pathify from "@/plugins/pathify"
import { make } from "vuex-pathify"

import auth from "@/modules/auth/store"
import maps from "@/modules/maps/store"
import users from "@/modules/users/store"
import localforage from "@/plugins/localforage"
import api from "@/utils/axios"
import router from "@/router"

import Layout from "@/assets/theme.json"

const state = {
  loading: true,
  drawer: null,
  title: null,
  controller: "",
  lang: "en",
  availableMaps: [],
  activeMap: "",
  activeFilesLayer: null,
  mapLayerFilesShown: false,
  refreshMapSwitcher: false,
  refreshOrgSwitcher: false,
  hostLayout: null,
  layoutError: null,
  tourStatus: "done",
  toast: {
    show: false,
    position: "top",
    color: "success",
    align: "right",
    mode: "multi-line",
    timeout: 2000,
    text: "",
  },
  alert: {
    show: false,
    dismissible: true,
    type: "success",
    text: "",
  },
  organisationId: null,
  impersonateActive: false,
  potreeInitialised: false,
}

const mutations = make.mutations(state)

const getters = {
  ...make.getters(state),

  layout(state, getters) {
    let layout = Layout
    if (getters.currentOrganisation?.layout) {
      layout = getters.currentOrganisation.layout
    } else if (state.hostLayout) {
      layout = state.hostLayout
    }
    return layout
  },

  currentOrganisation(state) {
    if (!state.auth.user?.organisations) return null

    // Priority 1. state.organisationId 2. domain 3. first in users org list (oldest)
    let currentOrg = state.auth.user.organisations.find(
      (org) => state.organisationId && org.id === state.organisationId
    )

    if (!currentOrg)
      currentOrg = state.auth.user.organisations.find((org) =>
        org.alt_domains.includes(location.hostname)
      )

    if (!currentOrg) currentOrg = state.auth.user.organisations[0]

    return currentOrg
  },

  activeMap(state, getters) {
    let maps = state.maps.maps
    let mapId = state.activeMap ? state.activeMap : router.currentRoute.params.id
    let mapObj = null
    mapObj = maps.find((m) => m.id === mapId)
    if (!mapObj) {
      // state.activeMap not in current orgs maps. Use default map.
      mapObj = maps.find((m) => m.id === getters.currentOrganisation?.map)
    }
    return mapObj
  },
}

const actions = {
  loadTourStatus({ commit }) {
    Vue.localStore.getItem("tourStatus").then((tourStatus) => {
      commit("SET_TOUR_STATUS", tourStatus)
    })
  },

  setTourStatus({ commit }, tourStatus) {
    Vue.localStore.setItem("tourStatus", tourStatus).then(() => {
      commit("SET_TOUR_STATUS", tourStatus)
    })
  },

  updateOrganisationId({ commit, dispatch, getters }, organisationId) {
    commit("SET_ORGANISATION_ID", organisationId, { root: true })

    let newCurrentOrg = getters.currentOrganisation
    // only cache orgid in localstorage if it's valid for that url
    if (
      newCurrentOrg?.domain === location.hostname ||
      newCurrentOrg?.alt_domains.indexOf(location.hostname) > -1
    ) {
      localforage.setItem("organisationId", organisationId).then(() => {
        dispatch("maps/loadMaps", "", { root: true })
        dispatch("users/getUsers", [], { root: true })
      })
    }
  },

  determineHostLayout({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.localStore.getItem("organisationId").then((orgId) => {
        api.getLayout(orgId).then((response) => {
          if (response?.status === 200) {
            const layout = response.data ? response.data : Layout
            commit("SET_HOST_LAYOUT", layout)
          }

          resolve()
        })
      })
    })
  },

  setActiveMap({ commit }, mapId) {
    Vue.localStore.setItem("activeMap", mapId).then(() => {
      commit("SET_ACTIVE_MAP", mapId)
    })
  },

  loadImpersonateActive({ commit }) {
    Vue.localStore.getItem("impersonateActive").then((active) => {
      if (active) commit("SET_IMPERSONATE_ACTIVE", true)
      else commit("SET_IMPERSONATE_ACTIVE", false)
    })
  },

  setImpersonateActive({ commit }, active) {
    if (active) {
      Vue.localStore.setItem("impersonateActive", active).then(() => {
        commit("SET_IMPERSONATE_ACTIVE", active)
      })
    } else {
      Vue.localStore.removeItem("impersonateActive").then(() => {
        commit("SET_IMPERSONATE_ACTIVE", active)
      })
    }
  },
}

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: { auth, maps, users },
  state,
  mutations,
  getters,
  actions,
})
